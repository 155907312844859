<template>
    <div>
        <v-data-table :headers="headers"
                      dense
                      :items="tramite.notificacion"
                      disable-pagination
                      hide-default-footer
                      class="elevation-2 mt-2">

            <template v-slot:item.destino="{ item }">
                {{item.neDestino.nombre}}
            </template>

            <template v-slot:item.direccionSoporte="{ item }">
                {{item.direccionSoporte}}
            </template>

            <template v-slot:item.asunto="{ item }">
                {{item.asunto}}
            </template>

            <template v-slot:item.mensaje="{ item }">
                {{item.mensaje}}
            </template>

            <template v-slot:item.envia="{ item }">
                <v-checkbox v-model="item.envia"></v-checkbox>
            </template>

            <template v-slot:item.link="{ item }">
                <v-checkbox v-model="item.link"></v-checkbox>
            </template>

            <template v-slot:item.editIcon="{ item }">
                <v-icon @click.stop="dialogChange(item)">
                    mdi-pen
                </v-icon>
            </template>

        </v-data-table>

        <v-dialog v-model="dialog"
                  max-width="1000px"
                  persistent>
            <AsuntoMensajeAdjuntoDialogo @closeDialog="dialogChange"
                                         :title='title'
                                         :asunto='asunto'
                                         :mensaje='mensaje'
                                         :direccionSoporte='direccionSoporte'
                                         @guardaInfo="changeAsuntoMensaje">
            </AsuntoMensajeAdjuntoDialogo>

        </v-dialog>
    </div>
</template>
<script>
    import { mapState, mapActions } from 'vuex';
    // Muestra en el tramite las posibles notificaciones
    import AsuntoMensajeAdjuntoDialogo from '@/components/NE/AsuntoMensajeAdjuntoDialogo';

    export default {
        name: 'neToSendGrid',

        data() {
            return {
                dialog: false,
                title: "",
                asunto: "",
                mensaje: "",
                direccionSoporte: "",
                localNE: {},
                headers: [
                    { text: 'Envía', value: 'envia', sortable: false },
                    { text: 'Link', value: 'link', sortable: false },
                    { text: 'Destino', value: 'destino', sortable: false },
                    { text: 'Dirección', value: 'direccionSoporte', sortable: false },
                    { text: 'Asunto', value: 'asunto', sortable: false },
                    { text: 'Mensaje', value: 'mensaje', sortable: false },
                    { text: '', value: 'editIcon', sortable: false },
                ]
            };
        },
        computed: {
            ...mapState('caso', ['tramite','emails'])
        },
        methods: {
            dialogChange(itm) {
                if (!this.dialog) {
                    this.title = itm.neDestino.nombre;
                    this.direccionSoporte = itm.direccionSoporte;
                    this.asunto = itm.asunto;
                    this.mensaje = itm.mensaje;
                    this.localNE = itm;
                }
                this.dialog = !this.dialog;
            },

            changeAsuntoMensaje(itm) {
                this.localNE.asunto = itm.asunto;
                this.localNE.mensaje = itm.mensaje;
                this.localNE.direccionSoporte = itm.direccionSoporte;
            }

        },
        components: {
            AsuntoMensajeAdjuntoDialogo
        }
    };
</script>

<style>
    .td {
        background-color: red;
    }
</style>
