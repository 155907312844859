<template>
    <v-select v-model="value"
              :dense="dense"
              :disabled="disabled"
              :items="all.items"
              item-text="nombre"
              item-value="id"
              label="Tipo de baja"
              @change="change"
              @focus="focus"
              :menu-props="{ auto: true, botton: true, offsetY: true }">
    </v-select>
</template>

<script>
    import { mapState, mapActions } from 'vuex';

    export default {
        name: 'selectTipobaja',
        props: ['value', 'texto', 'disabled', 'dense'],
        data: function () {
            return {
                pvalue: 0,
                ptexto: ''
            };
        },
        computed: {
            ...mapState('tipobaja', ['all'])
        },
        methods: {
            ...mapActions('tipobaja', ['getAll']),
            change(id) {
                this.$emit('input', id);
                this.$emit('change', id);
                var r = this.all.items.find(function (item) {
                    if (item.id === id) {
                        return item.nombre;
                    }
                });
                this.$emit('texto', r);
            },
            focus() {
                this.pvalue = this.value;
                this.ptexto = this.texto;
            }
        },
        mounted() {
            this.getAll();
        }
    };
</script>
