<template>
    <v-card class="pb-1">
        <ul class="pa-0">
            <li>
                <label class="pr-2" xs12>{{ tipoVehiculo }} / {{tipoBaja}}</label>
            </li>
            <li>
                <v-chip xs12
                        small
                        :text-color="text-color"
                        :color="color"
                        :dark="dark">
                    {{ porcentaje }}%
                </v-chip>
                <v-chip xs12
                        small
                        :text-color="text-color"
                        :color="color"
                        :dark="dark">
                    {{ fmtDouble(importe) }}
                </v-chip>
                <v-chip xs12
                        small
                        :text-color="text-color"
                        :color="color"
                        :dark="dark"
                        @click="$emit('importe',importe)">
                    <v-icon>mdi-upload</v-icon>
                </v-chip>
            </li>
        </ul>
    </v-card>
</template>

<script>
    export default {
        name: 'InfoCostoDesarmadero',
        props: [
            'texto',
            'text-color',
            'color',
            'dark',
            'infoauto',
            'porcentaje',
            'tipoVehiculo',
            'tipoBaja'],
        data() {
            return {
            };
        },
        computed: {
            importe() {
                return this.infoauto * this.porcentaje / 100;
            },

        },
        methods: {
            fmtDouble(val) {
                if (val == '' || val == null) {
                    return '$ 0.00';
                }
                return '$ ' + Number(val).toLocaleString();
            },
        }
    };
</script>
<style>
    ul {
        list-style-type: none;
    }
</style>
