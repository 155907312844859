<template>
    <v-bottom-navigation dark
                         background-color="blue-grey  lighten-5"
                         horizontal
                         fluid
                         height="26"
                         class="mt-0">

        <v-container fluid class="pa-0">
            <v-row>
                <v-layout>
                    <v-flex sm1 class="mr-0 pt-4">
                        <v-icon :color="alrt1 ? 'warning' : 'grey'"
                                @click="clkalerta1">
                            mdi-phone-classic
                        </v-icon>
                        <v-icon class="ml-2 pt-0"
                                :color="alrt2 ? 'primary' : 'grey'"
                                @click="clkalerta2">
                            mdi-calendar-clock
                        </v-icon>
                    </v-flex>
                    <v-col v-for="(item, inx) in tramites.estadoTramite"
                           :key="inx"
                           class="pt-1 pt-4">
                        <v-chip v-if="true"
                                class="pl-1 pr-1 pt-0 pb-0"
                                @click="newTramite(item.tramiteId)"
                                small
                                :color="item.default ? 'success' : 'primary'">
                            {{ item.tramite.nombre }}
                        </v-chip>
                    </v-col>
                </v-layout>
            </v-row>
        </v-container>
        <template>
            <v-dialog v-model="dialog"
                      max-width="400">
                <v-card>
                    <v-card-title class="pa-0">
                        <v-toolbar flat dense color="yellow">
                            <v-btn icon @click="close">
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                            <v-toolbar-title>
                                Alerta de llamado
                            </v-toolbar-title>
                        </v-toolbar>
                    </v-card-title>

                    <v-card-text>
                        <v-container grid-list-md>
                            <v-row>
                                <v-layout wrap>
                                    <v-flex xs12>
                                        <fecha v-model="alertaFechaIn"
                                               label="Fecha">
                                        </fecha>
                                    </v-flex>
                                    <v-flex xs12>
                                        <v-text-field v-model="alertaDescripcion"
                                                      label="Descripción">
                                        </v-text-field>
                                    </v-flex>
                                </v-layout>
                            </v-row>
                        </v-container>
                    </v-card-text>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn text @click="erase">Borra</v-btn>
                        <v-btn text @click="save">Confirma</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </template>
    </v-bottom-navigation>
</template>
<script>
    import { mapState, mapActions, mapGetters } from 'vuex';
    import fecha from '@/components/Tools/Fecha';

    export default {
        name: 'casoEstado',
        props: ['estadoId', 'completado'],
        data() {
            return {
                dialog: false,
                alertaId: 0,
                alertaDescripcion: '',
                alertaFechaIn: null,
                alertaFechaOut: null,
            };
        },
        computed: {
            ...mapState('caso', [
                'item',
                'defaultTramite',
                'tramite',
                'nuevoTramite',
                'todosTramitesCompletados'
            ]),

            ...mapState('estado', ['all']),

            ...mapGetters('caso', ['getAlerta']),

            alrt1() {
                if (this.item.casoAlerta == undefined) {
                    return 0;
                }
                let alrt = this.item.casoAlerta.find(x => x.alertaId == 1);
                if (alrt) {
                    return alrt.alertaId;
                }

                return 0;
            },
            alrt2() {
                if (this.item.casoAlerta == undefined) {
                    return 0;
                }
                let alrt = this.item.casoAlerta.find(x => x.alertaId == 2);
                if (alrt) {
                    return alrt.alertaId;
                }

                return 0;
            },
            propDialog: {
                get() {
                    return this.dialogTramite;
                },
                set(value) {
                    this.setDialogTramite(value);
                }
            },
            tramites() {
                this.tramitesCompletados();
                if (this.all.items !== undefined) {
                    var itmid = this.estadoId;
                    var r = this.all.items.find(function (itm) {
                        if (itmid === itm.id) {
                            return itm;
                        }
                    });
                    r.estadoTramite = r.estadoTramite.filter(
                        x => (
                            this.todosTramitesCompletados ||
                            (!this.todosTramitesCompletados &&
                                x.tramiteId !=
                                this.$store._modules.root.state.TRAMITES
                                    .FINALIZADO)) &&
                            (x.tramite.modulo & 1) == 1
                    );
                    return r;
                }
                return {};
            },
        },
        mounted() {
            this.getAll();
        },
        methods: {
            ...mapActions('caso', [
                'newTramite',
                'pushChild',
                'setDialogTramite',
                'setAlerta',
                'tramitesCompletados'
            ]),
            ...mapActions('tramite', ['getAll']),

            clkalerta1() {
                this.setAlerta({ alertaId: 1, value: !(this.alrt1 == 1) });

                if (this.alrt1 == 1)
                    this.setAlerta({ alertaId: 2, value: false });
            },

            clkalerta2() {
                this.dialog = true;
                let ret = this.getAlerta(2);
                this.alertaFechaIn = ret.fInicio;
                this.alertaFechaOut = ret.fCierre;
                this.alertaDescripcion = ret.descripcion;
                this.alertaId = ret.alertaId;
            },

            close() {
                this.dialog = false;
            },

            save() {
                this.setAlerta({
                    alertaId: 2,
                    value: true,
                    fechaIn: this.alertaFechaIn,
                    fechaOut: this.alertaFechaOut,
                    descripcion: this.alertaDescripcion
                });

                if (this.alrt1 == 1)
                    this.setAlerta({ alertaId: 1, value: false });
                this.close()
            },

            erase() {
                this.setAlerta({
                    alertaId: 2,
                    value: false
                });
                this.close()
            }

        },

        components: {
            fecha
        }
    };
</script>
