<template>
    <v-card class="pb-1">
        <ul class="pa-0">
            <li>
                <label class="pr-2" xs12>{{ modelo}}</label>
            </li>
            <li>
                <v-chip xs12
                        small
                        :text-color="text-color"
                        :color="color"
                        :dark="dark">
                    {{ this.item.anio }}
                </v-chip>
                <v-chip xs12
                        small
                        :text-color="text-color"
                        :color="color"
                        :dark="dark">
                    {{ fmtDouble(importe) }}
                </v-chip>
                <v-chip xs12
                        small
                        :text-color="text-color"
                        :color="color"
                        :dark="dark"
                        @click="$emit('importe',importe)">
                    <v-icon>mdi-upload</v-icon>
                </v-chip>
            </li>
        </ul>
    </v-card>
</template>

<script>
    import { mapState, mapActions } from 'vuex';

    export default {
        name: 'InformacionInfoauto',
        props: [
            'texto',
            'text-color',
            'color',
            'dark'],
        data() {
            return {
            };
        },
        computed: {
            ...mapState('caso', ['item']),
            ...mapState('modelovalor', { valorModeloItem: 'item' }),

            modelo() {
                return this.item.modeloValor.marcaId != null &&
                    this.item.modeloValor.modId != '' ? this.item.modeloValor.marca + ' ' +
                    this.item.modeloValor.modelo + ' ' + this.item.modeloValor.tipo : this.item.marca.nombre + ' ' + this.item.modelo.nombre;
            },

            modval() {
                return this.valorModeloItem;
            },

            importe() {
                let ret;

                if (this.item.modeloValor.modeloValorAnio != undefined) {
                    ret = this.item.modeloValor.modeloValorAnio.find(x => x.anio == this.item.anio);
                }

                if (ret == undefined)
                    return 0;
                return ret.valor;
            }
        },
        methods: {
            fmtDouble(val) {
                return '$ ' + this.importe;
            },
        }
    };
</script>
<style>
    ul {
        list-style-type: none;
    }
</style>
