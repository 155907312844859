<template>
    <v-card>
        <v-card-title class="pa-0">
            <v-toolbar flat dense color="yellow">
                <v-btn icon @click="close">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-toolbar-title>
                    {{ title }}
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn text @click="guarda">Guarda</v-btn>
            </v-toolbar>
        </v-card-title>

        <v-card-text>
            <v-container grid-list-md>
                <v-row>
                    <v-layout wrap>
                        <v-flex xs12>
                            <v-text-field v-model="localDireccionSoporte"
                                          label="Dirección">
                            </v-text-field>
                        </v-flex>
                        <v-flex xs12>
                            <v-text-field v-model="localAsunto"
                                          label="Asunto">
                            </v-text-field>
                        </v-flex>
                        <p>{xxxx} - # Siniestro / {yyyy} Dominio</p>
                        <v-flex xs12>
                            <v-textarea v-model="localMensaje"
                                        label="Mensaje">
                            </v-textarea>
                        </v-flex>

                    </v-layout>
                </v-row>
            </v-container>
        </v-card-text>

    </v-card>
</template>

<script>
    import { mapState, mapActions } from 'vuex';

    export default {
        name: 'AsuntoMensajeAdjuntoDialogo',

        props: ['title',
            'asunto',
            'mensaje',
            'direccionSoporte'
        ],

        data() {
            return {
                localAsunto: this.asunto,
                localMensaje: this.mensaje,
                localDireccionSoporte: this.direccionSoporte
            };
        },
        watch: {
            asunto(newVal) {
                this.localAsunto = newVal;
            },
            mensaje(newVal) {
                this.localMensaje = newVal;
            },
            direccionSoporte(newVal) {
                this.localDireccionSoporte = newVal;
            },
        },
        computed: {
            ...mapState('cliente', ['item']),

        },

        methods: {

            guarda() {
                this.$emit("guardaInfo", { asunto: this.localAsunto, mensaje: this.localMensaje, direccionSoporte: this.localDireccionSoporte });
                this.close();
            },

            close() {
                this.$emit("closeDialog");
            }
        },
    };
</script>
