var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.getGrant('0103'))?_c('casoEstado',{attrs:{"estadoId":_vm.item.estadoId}}):_vm._e(),(_vm.getGrant('0300'))?_c('casoTramiteSeguimiento'):_vm._e(),_c('v-data-table',{staticClass:"elevation-2 mt-2",attrs:{"headers":_vm.headers,"dense":"","items":_vm.item.casoTramite,"disable-pagination":"","hide-default-footer":""},on:{"click:row":_vm.editTramite},scopedSlots:_vm._u([{key:"item.nombre",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.nombreTramite(item.tramiteId))+" ")]}},{key:"item.completado",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"color":item.completado ? 'green' : 'red'}},[_vm._v(" "+_vm._s(_vm.completado(item.completado))+" ")])]}},{key:"item.icono",fn:function(ref){
var item = ref.item;
return [_c('v-icon',[_vm._v(" "+_vm._s(_vm.icono(item.tramiteId))+" ")])]}},{key:"item.fInicio",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.fechaFrmt(item.fInicio))+" ")]}},{key:"item.fCierre",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.fechaCierreFrmt(item.fCierre, item.fInicio))+" ")]}},{key:"item.applicationUser.abrv",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.user(item))+" ")]}}])}),[_c('v-dialog',{attrs:{"max-width":"1000px","persistent":""},model:{value:(_vm.propDialog),callback:function ($$v) {_vm.propDialog=$$v},expression:"propDialog"}},[_c('casoTramiteDialogo')],1)]],2)}
var staticRenderFns = []

export { render, staticRenderFns }