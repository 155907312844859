var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"elevation-2 mt-2",attrs:{"headers":_vm.headers,"dense":"","items":_vm.tramite.notificacion,"disable-pagination":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.destino",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.neDestino.nombre)+" ")]}},{key:"item.direccionSoporte",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.direccionSoporte)+" ")]}},{key:"item.asunto",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.asunto)+" ")]}},{key:"item.mensaje",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.mensaje)+" ")]}},{key:"item.envia",fn:function(ref){
var item = ref.item;
return [_c('v-checkbox',{model:{value:(item.envia),callback:function ($$v) {_vm.$set(item, "envia", $$v)},expression:"item.envia"}})]}},{key:"item.link",fn:function(ref){
var item = ref.item;
return [_c('v-checkbox',{model:{value:(item.link),callback:function ($$v) {_vm.$set(item, "link", $$v)},expression:"item.link"}})]}},{key:"item.editIcon",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{on:{"click":function($event){$event.stopPropagation();return _vm.dialogChange(item)}}},[_vm._v(" mdi-pen ")])]}}])}),_c('v-dialog',{attrs:{"max-width":"1000px","persistent":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('AsuntoMensajeAdjuntoDialogo',{attrs:{"title":_vm.title,"asunto":_vm.asunto,"mensaje":_vm.mensaje,"direccionSoporte":_vm.direccionSoporte},on:{"closeDialog":_vm.dialogChange,"guardaInfo":_vm.changeAsuntoMensaje}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }