<template>
    <v-layout row wrap class="pa-0 mt-0">
        <v-autocomplete :disabled="disabled"
                        v-model="propValue"
                        :items="all.items"
                        item-text="nombre"
                        item-value="id"
                        :label="etiqueta"
                        :prepend-inner-icon="icono"
                        :loading="isLoading"
                        :search-input.sync="search"
                        placeholder="Busca o agrega"
                        @click:prepend-inner="clickIcon"
                        @input="update">
        </v-autocomplete>

        <v-dialog v-model="dialog"
                  max-width="500px"
                  max-heigh="300px"
                  persistent>
            <titularDialogo @closeDlg="dialog = false"
                            @modelo="change($event)" />
        </v-dialog>
    </v-layout>
</template>

<script>
    import { mapState, mapActions } from 'vuex';

    import titularDialogo from '@/components/Titular/TitularDialogo';

    export default {
        name: 'autocompletaTitular',
        props: ['value', 'titulo', 'disabled'],
        data: () => ({
            dialog: false,
            search: ""
        }),

        methods: {
            ...mapActions('titular', [
                'getAutocomplete',
                'setItems',
                'getById',
                'setItem'
            ]),

            update(newValue) {
                let val = this.all.items.find(x => x.id === newValue)
                if (val != null && val !== undefined) {
                    this.propValue = val;
                    this.$emit('change');
                }
            },

            autocompleta(val) {
                this.getAutocomplete(val);
            },

            clickIcon() {
                if (this.value.id == null || this.value.id == 0)
                    this.setItem(Object.assign({}, this.defaultTitular));
                else {
                    this.getById(this.value.id);
                }
                this.dialog = true;
            },

            change(item) {
                item.nombre = item.fullName;
                this.propValue = item;
                this.$emit('change');
            },

        },

        watch: {

            search(val) {
                if (val === null || val === '' ||
                    (val.nombre === undefined && val.id !== undefined) ||
                    val === this.propValue.nombre) {
                    if (val === null || val === '') {
                        this.propValue = { id: null, nombre: val };
                        this.setItems([]);
                    }
                    return;
                }
                if (this.propValue.id !== null) {
                    this.setItems([]);
                }

                // Items have already been loaded
                if (this.all.items.length > 0 ||
                    this.all.items == undefined ||
                    this.isLoading ||
                    val === null ||
                    val === '' 
                ) return;

                this.autocompleta(val);

                this.propValue = { id: null, nombre: val };
            },
        },

        computed: {
            ...mapState('titular', ['all', 'isLoading', 'defaultTitular']),

            icono() {
                return this.propValue.id == 0 || this.propValue.id == null
                    ? 'mdi-plus'
                    : 'mdi-pencil';
            },

            propValue: {
                get() {
                    if (this.value.id == null) {
                        //this.setItems([]);
                        return this.value;
                    }
                    else {
                        let pp = { id: this.value.id, nombre: this.value.fullName };
                        this.setItems([pp]);
                        return pp;
                    }
                },

                set(value) {
                    this.$emit('input', { id: value.id, nombre: value.nombre, fullName: value.nombre });
                }
            },

            etiqueta() {
                return this.titulo + (this.propValue.id == null ? '' : (this.propValue.id + ' ' + this.propValue.nombre));
            }
        },
        components: {
            titularDialogo
        }
    }
</script>
