<template>
    <v-bottom-navigation horizontal
                         dark
                         fluid
                         background-color="blue-grey lighten-5"
                         class="mt-3"
                         height="26">
        <v-container fluid class="pa-0">
            <v-row>
                <v-layout>
                    <v-col class="pt-4"
                           v-for="(item, inx) in tramites" :key="inx">
                        <v-chip @click="newTramite(item.id)"
                                small
                                :color="'grey'">
                            {{ item.nombre }}
                        </v-chip>
                    </v-col>
                </v-layout>
            </v-row>
        </v-container>
    </v-bottom-navigation>
</template>

<script>
    import { mapState, mapActions } from 'vuex';

    export default {
        name: 'casoTramiteSeguimiento',
        data() {
            return {
                alerta: false
            };
        },

        computed: {
            ...mapState('caso', [
                'item',
                'defaultTramite',
                'tramite',
                'dialogTramite'
            ]),
            ...mapState('tramite', ['items']),

            propDialog: {
                get() {
                    return this.dialogTramite;
                },

                set(value) {
                    this.setDialogTramite(value);
                }
            },

            tramites() {
                if (this.items !== undefined) {
                    var ret = this.items.filter(x => (x.modulo & 2) == 2);
                    return ret;
                }
                return [];
            }
        },

        mounted() {
            this.getAll();
        },

        methods: {
            ...mapActions('caso', [
                'newTramite',
                'pushChild',
                'setDialogTramite',
                'setAlerta',
                'tramitesCompletados'
            ]),
            ...mapActions('tramite', ['getAll']),

            clkalerta() {
                this.alerta = !this.alerta;
                this.setAlerta({ alertaId: 1, value: this.alerta });
            }
        }
    };
</script>
