<template>
    <v-card>
        <v-card-title class="pa-0">
            <v-toolbar flat dense color="yellow">
                <v-btn icon @click="closeDialogTramite">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-toolbar-title>
                    {{ titulo }}
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn class="mr-4"
                       dark
                       rounded
                       small
                       color="error"
                       @click="GetEmailsSended">
                    <v-icon>mdi-contact-mail</v-icon>
                </v-btn>
                <v-btn class="mr-4"
                       dark
                       rounded
                       small
                       color="error"
                       @click="send">
                    <v-icon>mdi-email-open-outline</v-icon>
                </v-btn>
                <v-btn dark
                       rounded
                       small
                       color="error"
                       @click="save">
                    Guarda
                </v-btn>
            </v-toolbar>
        </v-card-title>

        <v-card-text>
            <v-container grid-list-md>
                <v-row>
                    <v-layout wrap>
                        <!-- 'Inicio' -->
                        <v-flex xs6 v-if="viewCampo(1)">
                            <fecha class="pt-4" v-model="tramite.fInicio"
                                   :label="lblCampo(1)"></fecha>
                        </v-flex>
                        <!-- 'Cierre' -->
                        <v-flex xs6 v-if="viewCampo(2)">
                            <fecha v-model="tramite.fCierre"
                                   :label="lblCampo(2)"></fecha>
                        </v-flex>
                        <!--        'Estado de informe'  'Registro   Deuda x Patentes    Deuda x Multas-->
                        <div v-if="
                                viewCampo(3) ||
                                    viewCampo(4) ||
                                    viewCampo(5) ||
                                    viewCampo('6')
                            ">
                            <v-container grid-list-md>
                                <v-row>
                                    <v-layout wrap>
                                        <!-- 'Estado de informe' -->
                                        <v-flex xs6 sm6 v-if="viewCampo(3)">
                                            <selectEstadoInforme v-model="item.estadoInforme.id"
                                                                 :texto="
                                                    item.estadoInforme.nombre
                                                "
                                                                 @texto="
                                                    item.estadoInforme.nombre = $event
                                                ">
                                            </selectEstadoInforme>
                                        </v-flex>
                                        <!-- 'Registro' -->
                                        <v-flex xs6 sm6 v-if="viewCampo(4)">
                                            <selectRegistro v-model="item.registro.id"
                                                            :texto="item.registro.nombre"
                                                            @texto="
                                                    item.registro.nombre = $event
                                                ">
                                            </selectRegistro>
                                        </v-flex>
                                        <!-- 'Deuda x Patentes' -->
                                        <v-flex xs6 v-if="viewCampo(5)">
                                            <v-text-field v-model="item.deudaPatente"
                                                          type="number"
                                                          :label="lblCampo(5)"></v-text-field>
                                        </v-flex>
                                        <!-- 'Deuda x Multas' -->
                                        <v-flex xs6 v-if="viewCampo(6)">
                                            <v-text-field v-model="item.deudaMulta"
                                                          type="number"
                                                          :label="lblCampo(6)"></v-text-field>
                                        </v-flex>
                                    </v-layout>
                                </v-row>
                            </v-container>
                        </div>

                        <v-container grid-list-md>
                            <v-row>
                                <v-layout wrap>
                                    <!-- 'Desarmadero' -->
                                    <v-flex xs12 sm6 v-if="viewCampo(7)">
                                        <selectDesarmadero class="pt-4"
                                                           v-model="item.desarmadero.id"
                                                           :texto="item.desarmadero.nombre"
                                                           :clienteId="item.cliente.id"
                                                           @change="changeDesarmadero"
                                                           @texto="item.desarmadero.nombre = $event"
                                                           :rules="[v=> !!v || 'Item is required']">
                                        </selectDesarmadero>
                                        <div v-show="tramite.completado == 0">
                                            <span style="color:blue"
                                                  class="ma-0"
                                                  v-if="diasrudac == null">
                                                Desarmadero sin fecha de
                                                vencimiento de RUDAC
                                            </span>
                                            <span style="color:red"
                                                  class="ma-0"
                                                  v-else-if="
                                                  diasrudac>
                                                0 &&
                                                diasrudac <= 20
                                                "
                                                >RUDAC vence en
                                                {{ diasrudac }} días
                                            </span>
                                            <span style="color:orange"
                                                  class="ma-0"
                                                  v-else-if="diasrudac <= 30">
                                                RUDAC vencido
                                                {{ Math.abs(diasrudac) }}
                                                días
                                            </span>
                                            <span style="color:green"
                                                  class="ma-0"
                                                  v-else-if="diasrudac > 30">
                                                RUDAC vence en
                                                {{ diasrudac }} días
                                            </span>
                                        </div>
                                    </v-flex>
                                </v-layout>
                            </v-row>
                        </v-container>

                        <!-- '# Comprobante'  'Fecha'   'Importe' -->
                        <v-flex v-if="viewCampo(8) || viewCampo(9) || viewCampo(10)">
                            <v-container grid-list-md>
                                <v-row>
                                    <v-layout wrap>
                                        <!-- '# Comprobante' -->
                                        <v-flex xs6 sm4 v-if="viewCampo(8)">
                                            <v-text-field v-model="tramite.numeroComprobante"
                                                          :label="lblCampo(8)"></v-text-field>
                                        </v-flex>
                                        <!-- 'Fecha' -->
                                        <v-flex xs6 sm4 v-if="viewCampo(9)">
                                            <fecha v-model="tramite.fechaComprobante"
                                                   :label="lblCampo(9)"></fecha>
                                        </v-flex>
                                        <!--  'Importe' -->
                                        <v-flex xs6 sm4 v-if="viewCampo(10)">
                                            <v-text-field v-model="tramite.importeComprobante"
                                                          type="number"
                                                          :label="lblCampo(10)"></v-text-field>
                                        </v-flex>
                                    </v-layout>
                                </v-row>
                            </v-container>
                        </v-flex>

                        <!-- 'Gestor' -->
                        <v-flex xs6 v-if="viewCampo(11)">
                            <selectGestor v-model="item.gestor.id"
                                          :texto="item.gestor.nombre"
                                          :clienteId="item.cliente.id"
                                          @texto="item.gestor.nombre = $event">
                            </selectGestor>
                        </v-flex>

                        <!--        'Costo Gestoria'  'Costo Traslado'   'Infoauto'        'Total' -->
                        <v-flex v-if="
                                viewCampo(14) ||
                                    viewCampo(15) ||
                                    viewCampo(16) ||
                                    viewCampo(17) ||
                                    viewCampo(19)
                            ">
                            <v-container grid-list-md>
                                <v-row>
                                    <v-layout wrap>
                                        <!-- 'Costo gestoria' -->
                                        <v-flex xs6 sm4 v-if="viewCampo(14)">
                                            <informacion :titulo="lblCampo(14)"
                                                         :texto="fmtDouble(costoGestoria)">
                                            </informacion>
                                        </v-flex>

                                        <!-- 'Costo traslado' -->
                                        <v-flex xs6 sm3 v-if="viewCampo(15)">
                                            <informacion :titulo="lblCampo(15)"
                                                         :texto="fmtDouble(costoTraslado)">
                                            </informacion>
                                        </v-flex>

                                        <!--  'Infoauto' -->
                                        <v-flex xs6 sm2 v-if="viewCampo(16)">
                                            <informacion :titulo="lblCampo(16)"
                                                         :texto="fmtDouble(costoInfoauto)">
                                            </informacion>
                                        </v-flex>

                                        <!--  'Calculo' -->
                                        <v-flex xs6 sm5 v-if="viewCampo(18)">
                                            <infoCostoDesarmadero :tipoVehiculo="dtvtb.tVehiculo"
                                                                  :tipoBaja="dtvtb.tBaja"
                                                                  :porcentaje="dtvtb.porcentaje"
                                                                  :infoauto="costoInfoauto"
                                                                  @importe="tramite.importeComprobante=$event">
                                            </infoCostoDesarmadero>
                                        </v-flex>

                                        <!--  'Total' -->
                                        <v-flex xs6 sm2 v-if="viewCampo(17)">
                                            <informacion :titulo="lblCampo(17)"
                                                         :texto="fmtDouble(costoTotal)">
                                            </informacion>
                                        </v-flex>

                                        <!--  'Infoauto datos de tabla' -->
                                        <v-flex xs6 sm12 v-if="viewCampo(19)">
                                            <informacionInfoauto @importe="tramite.importeComprobante=$event">
                                            </informacionInfoauto>
                                        </v-flex>
                                    </v-layout>
                                </v-row>
                            </v-container>
                        </v-flex>

                        <!-- 'Detalle de trámite' -->
                        <v-flex xs10 v-if="viewCampo(12)">
                            <v-textarea v-model="tramite.descripcion"
                                        auto-grow
                                        rows="2"
                                        dense
                                        :label="lblCampo(12)"></v-textarea>
                        </v-flex>

                        <!-- 'Completado' -->
                        <v-flex xs2 v-if="viewCampo(13)">
                            <v-checkbox :disabled="disabledCompletado"
                                        v-model="tramite.completado"
                                        class="mx-2"
                                        :label="lblCampo(13)"
                                        @click="completa"></v-checkbox>
                        </v-flex>

                        <v-flex xs12 v-if="NotificacionesCount">
                            <neToSendGrid v-model="tramite.notificacion"
                                          @change="changeNE($event)">
                            </neToSendGrid>
                        </v-flex>
                    </v-layout>
                </v-row>
            </v-container>
        </v-card-text>
        <template>
            <v-dialog v-model="propNEDialog" max-width="1500px" persistent>
                <neTramite />
            </v-dialog>
        </template>
    </v-card>
</template>

<script>
    import { mapState, mapActions } from 'vuex';

    import fecha from '@/components/Tools/Fecha';
    import informacion from '@/components/Tools/Informacion';
    import infoCostoDesarmadero from '@/components/Tools/InfoCostoDesarmadero';
    import informacionInfoauto from '@/components/Tools/InformacionInfoauto';
    import selectDesarmadero from '@/components/Selects/selectDesarmadero';
    import selectEstadoInforme from '@/components/Selects/selectEstadoInforme';
    import selectGestor from '@/components/Selects/selectGestor';
    import selectRegistro from '@/components/Selects/selectRegistro';
    import neToSendGrid from '@/components/NE/NEToSendGrid';
    import neTramite from '@/components/NE/NETramite';

    export default {
        name: 'casoTramiteDialogoBajaSeguimiento',
        data() {
            return {
                tipoVehiculo: null,
                completado: false,
                editedIndex: -1,
                diasRudac: 0,
            };
        },
        computed: {
            ...mapState(['TRAMITES']),
            ...mapState('campotramite', { campotramiteAll: 'items' }),
            ...mapState('modelovalor', { modeloValorItem: 'item' }),
            ...mapState('modelovalortipo', { modeloValorTipoAll: 'items' }),
            ...mapState('caso', ['item', 'modificado', 'nuevoTramite', 'tramite']),
            ...mapState('tramite', ['items']),
            ...mapState('account', ['loggedUser']),
            ...mapState('desarmadero', ['itemsDTVTB']),
            ...mapState('neNotificacion', ['dialogNETramite']),

            propNEDialog: {
                get() {
                    return this.dialogNETramite;
                },
                set(value) {
                    this.setDialogNETramite(value);
                }
            },

            NotificacionesCount() {
                return this.tramite.notificacion.length > 0;
            },

            TipoVehiculo() {
                if (!this.item.modeloValorId) {
                    return this.item.modelo.tipVehiculoId;
                }
                else {
                    let cod = this.modeloValorAll.find(x => x.modeloValorId == this.item.modeloValorId);
                    let tipo = this.modeloValorTipoAll.find(x => x.tipo == cod.tipoId);
                    return tipo.tipVehiculoId;
                }
            },

            dtvtb() {

                let d = this.itemsDTVTB.find(x =>
                    x.desarmaderoId == this.item.desarmadero.id &&
                    x.tBajaId == this.item.tipoBaja.id &&
                    x.tVehiculoId == this.tipoVehiculo);
                if (d == undefined)
                    d = {
                        desarmaderoId: 0,
                        tBajaId: 0,
                        tVehiculoId: 0,
                        tipoBaja: '',
                        tVehiculo: '',
                        porcentaje: 0
                    };
                return d;
            },

            costoGestoria() {
                return this.calculaCostoGestoria();
            },

            costoTraslado() {
                return this.calculaCostoTraslado();
            },

            costoInfoauto() {
                return this.calculaCostoInfoauto();
            },

            costoTotal() {
                switch (this.tramite.tramiteId) {
                    case this.TRAMITES.COBRO_A_DESARMADEROS:
                        var ret =
                            Number(this.calculaCostoTraslado()) +
                            Number(this.tramite.importeComprobante);
                        return ret;
                    case this.TRAMITES.COBRO_OTROS_GASTOS:
                        ret =
                            this.calculaCostoTraslado() +
                            this.calculaCostoGestoria();
                        return ret;
                    default:
                        return 0;
                }
            },

            //   this.tramite.tramiteId == this.TRAMITES.ANULAR
            //   this.tramite.tramiteId == this.TRAMITES.REACTIVAR ||
            //|| this.tramite.tramiteId == this.TRAMITES.OBSERVACION
            //|| this.tramite.tramiteId == this.TRAMITES.OBSERVACION_INTERNA
            //|| this.tramite.tramiteId == this.TRAMITES.OTROS_GASTOS
            //|| this.tramite.tramiteId == this.TRAMITES.INICIABAJA
            //|| this.tramite.tramiteId == this.TRAMITES.PREINFORME
            //|| this.tramite.tramiteId == this.TRAMITES.ORDEN_DE_TRASLADO
            //|| this.tramite.tramiteId == this.TRAMITES.PEDIDO_DE_TRASLADO
            //|| this.tramite.tramiteId == this.TRAMITES.PEDIDO_04D
            //|| this.tramite.tramiteId == this.TRAMITES.GESTORIA
            //|| this.tramite.tramiteId == this.TRAMITES.BAJA_A_COMPANIA_MAIL
            //|| this.tramite.tramiteId == this.TRAMITES.DOCUMENTACION_FISICA_A_COMPANIA
            //|| this.tramite.tramiteId == this.TRAMITES.ACREEDOR_PRENDARIO
            //|| this.tramite.tramiteId == this.TRAMITES.FINALIZADO
            //|| this.tramite.tramiteId == this.TRAMITES.VALORES_INFOAUTO
            //|| this.tramite.tramiteId == this.TRAMITES.COBRO_A_DESARMADEROS
            //|| this.tramite.tramiteId == this.TRAMITES.COBRO_GESTORIA
            //|| this.tramite.tramiteId == this.TRAMITES.COBRO_OTROS_GASTOS
            //|| this.tramite.tramiteId == this.TRAMITES.PAGO_A_COMPANÍA
            //|| this.tramite.tramiteId == this.TRAMITES.FC_COMPANIA_A_DESARMADERO
            //|| this.tramite.tramiteId == this.TRAMITES.COMISION_BAJA

            titulo() {
                var trmtId = this.tramite.tramiteId;
                var r = this.items.find(function (itm) {
                    if (trmtId === itm.id) {
                        return itm;
                    }
                });
                if (r == undefined)
                    return "";

                return r.nombre;
            },

            disabledCompletado() {
                var tmt = this.items.find(x => x.id == this.tramite.tramiteId);
                if (tmt == null) return false;
                const self = this;

                var cmp = tmt.tramiteCampo.filter(x => x.obligatorio);

                var field = cmp.map(item => {
                    const field = self.campotramiteAll.find(x =>
                        x.id == item.campoTramiteId
                        && x.campoTramiteName != ''
                    );
                    return field == undefined ? undefined : field.campoTramiteName;
                });
                let ret = true;
                field.forEach(item => {
                    ret &= (item == undefined ? true : this.tramite[item] != null);
                });
                return !ret || this.tramite.completado;
            },

            diasrudac() {
                if (this.item.desarmadero.vencimientoRUDAC == null) return null;

                var milliseconds = 24 * 60 * 60 * 1000;
                var rudac = new Date(
                    this.item.desarmadero.vencimientoRUDAC
                ).getTime();
                var inicio = new Date().getTime();
                var ret = Math.round((rudac - inicio) / milliseconds);
                return ret;
            }
        },

        methods: {
            ...mapActions('caso', ['closeDialogTramite', 'pushChild', 'setState', 'setModificado', 'setNotificacionCaso', 'setNotificacionTramite']),
            ...mapActions('desarmadero', ['checkVencimientoById']),
            ...mapActions('alert', ['errorTime', 'error']),
            ...mapActions('modelovalor', ['getById']),
            ...mapActions('neNotificacion', ['getNETramite','setDialogNETramite']),
            //    TipoVehiculo() {
            //    let self = this;
            //    if (!this.item.modeloValorId) {
            //        return this.item.modelo.tipVehiculoId;
            //    }
            //    else {
            //        this.getById(self.item.modeloValorId)
            //            .then(() => {
            //                let cod = self.modeloValorItem;
            //                let tipo = self.modeloValorTipoAll.find(x => x.tipo == cod.tipoId);
            //                return tipo.tipVehiculoId;
            //            });
            //    }
            //},

            calculaCostoGestoria() {
                var tmt_gestoria = this.item.casoTramite.find(
                    x => x.tramiteId == this.TRAMITES.GESTORIA
                );
                if (tmt_gestoria == undefined) {
                    return 0;
                }
                return tmt_gestoria.importeComprobante;
            },

            calculaCostoTraslado() {
                var tmt_traslado = this.item.casoTramite.find(
                    x => x.tramiteId == this.TRAMITES.PEDIDO_DE_TRASLADO
                );
                if (tmt_traslado == undefined) {
                    return 0;
                }
                return tmt_traslado.importeComprobante;
            },

            calculaCostoInfoauto() {
                var tmt_infoauto = this.item.casoTramite.find(
                    x => x.tramiteId == this.TRAMITES.VALORES_INFOAUTO
                );
                if (tmt_infoauto == undefined) {
                    return 0;
                }
                return tmt_infoauto.importeComprobante;
            },

            changeNE(notificaciones) {
                this.setNotificacionTramite(notificaciones);
            },

            viewCampo(campo) {
                var tmt = this.items.find(x => x.id == this.tramite.tramiteId);
                if (tmt == null) return false;
                var cmp = tmt.tramiteCampo.find(x => x.campoTramiteId == campo);
                return cmp != undefined;
            },

            lblCampo(campo) {
                var tmt = this.items.find(x => x.id == this.tramite.tramiteId);
                if (tmt == null) return '';
                var cmp = tmt.tramiteCampo.find(x => x.campoTramiteId == campo);
                return cmp != undefined ? cmp.nombre : '';
            },

            changeDesarmadero(item) {
                // var finicio = this.tramite.fInicio;
                this.item.desarmadero.vencimientoRUDAC = item.vencimientoRUDAC;
                //                this.checkVencimientoById({ id, finicio });
            },

            valida(completa) {
                var ok = true;

                if (this.tramite.fInicio == null) {
                    ok = false;
                    this.errorTime('Debe tener fecha de inicio');
                } else {
                    if (
                        completa &&
                        this.tramite.fCierre == null &&
                        (this.tramite.tramiteId == this.TRAMITES.PREINFORME ||
                            this.tramite.tramiteId == this.TRAMITES.PEDIDO_DE_TRASLADO ||
                            this.tramite.tramiteId == this.TRAMITES.PEDIDO_04D ||
                            this.tramite.tramiteId == this.TRAMITES.GESTORIA)
                    ) {
                        ok = false;
                        this.errorTime('Debe tener fecha de cierre');

                    } else {
                        if (
                            completa &&
                            ok &&
                            (this.tramite.tramiteId == this.TRAMITES.PREINFORME ||
                                this.tramite.tramiteId ==
                                this.TRAMITES.PEDIDO_DE_TRASLADO ||
                                this.tramite.tramiteId == this.TRAMITES.PEDIDO_04D ||
                                this.tramite.tramiteId == this.TRAMITES.GESTORIA) &&
                            this.tramite.fInicio.substr(0, 10) > this.tramite.fCierre.substr(0, 10)
                        ) {
                            ok = false;
                            this.error('La fecha de inicio debe ser anterior a la fecha de cierre');
                        }
                    }
                }
                if (ok)
                    this.setModificado(true);

                return ok;
            },

            send() {
                this.setNotificacionCaso(this.tramite.notificacion);
                this.closeDialogTramite();
            },

            GetEmailsSended() {
                this.propNEDialog = true;
                this.getNETramite(this.tramite.id);
            },

            save() {
                var slf = this;
                let ret = this.valida(false);
                if (ret) {
                    slf.tramite.applicationUserId = slf.loggedUser.id;
                    slf.tramite.applicationUser = null;
                    if (slf.tramite.nuevoTramite) {
                        slf.pushChild(slf.tramite);
                    }
                    slf.setState();
                    slf.setNotificacionCaso(slf.tramite.notificacion);
                    slf.closeDialogTramite();
                }
            },

            completa() {
                var slf = this;
                //this.tramite.completado = this.valida(true);
                //if (this.tramite.completado) {
                var trmt = 0;

                this.items.find(function (itm, i) {
                    if (slf.tramite.tramiteId === itm.id) {
                        trmt = i;
                    }
                });
                if (this.tramite.tramiteId >= this.TRAMITES.INICIABAJA) {
                    // observaciones no cambia estado
                    this.item.estadoId = this.items[trmt].estadoFinal.id;
                    this.item.estado.id = this.items[trmt].estadoFinal.id;
                    this.item.estado.nombre =
                        this.items[trmt].estadoInicial.nombre;
                }
                this.tramite.completado = true;
                this.save();
                //}
            },

            fmtDouble(val) {
                if (val == '' || val == null) {
                    return '$ 0.00';
                }
                return '$ ' + Number(val).toLocaleString();
            },
        },

        components: {
            fecha,
            informacion,
            infoCostoDesarmadero,
            informacionInfoauto,
            neTramite,
            neToSendGrid,
            selectDesarmadero,
            selectEstadoInforme,
            selectGestor,
            selectRegistro
        }
    };
</script>
