<template>
    <v-card>
        <v-card-title class="pa-0">
            <v-toolbar flat color="yellow">
                <v-btn icon @click="closeDialogNETramite">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-toolbar-title>Notificaciones</v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
                Compañia: {{item.cliente.nombre}}
                <v-divider class="mx-4" inset vertical></v-divider>
                Dominio: {{item.nombre}}
                <v-spacer></v-spacer>
            </v-toolbar>
        </v-card-title>
        <v-card-text>
            <v-data-table :headers="headers"
                          dense
                          disable-pagination
                          hide-default-footer
                          :items="items"
                          :search="search"
                          class="elevation-1"
                          @click:row="editItem">

                <template v-slot:item.timeStamp="{ item }">
                    {{ fechaNotificacion(item.timeStamp) }}
                </template>
                <template v-slot:item.soporte>
                    <v-icon small>
                        mdi-email
                    </v-icon>
                </template>
                <template v-slot:no-data>
                    <v-btn color="yellow" @click="initialize">Reset</v-btn>
                </template>
            </v-data-table>
        </v-card-text>
    </v-card>
</template>

<script>
    import { mapState, mapActions } from 'vuex';

    import AsuntoMensajeAdjuntoDialogo from '@/components/NE/AsuntoMensajeAdjuntoDialogo';

    export default {
        name: 'neTramite',
        data() {
            return {
                search: '',
                formTitle: '',
                headers: [
                    //{ text: 'Id', align: 'right', sortable: true, value: 'id', show: 'false' },
                    { text: 'Destino', align: 'center', sortable: true, value: 'destino' },
                    { text: 'soporte', align: 'center', value: 'soporte' },
                    { text: 'Dirección', align: 'center', sortable: true, value: 'direccionSoporte' },
                    { text: 'Fecha de envío notificación', align: 'right', sortable: true, value: 'timeStamp', show: 'false', width: 10 },
                    { text: 'Asunto', align: 'left', sortable: true, value: 'asunto' },
                    { text: 'Mensaje', align: 'left', sortable: true, value: 'mensaje' },
                    //{ text: 'Estado', align: 'center', sortable: true, value: 'estado' },
                ]
            };
        },

        computed: {
            ...mapState('neNotificacion', ['items']),
            ...mapState('caso', ['item']),
        },

        //created() {
        //    this.initialize();
        //},

        methods: {
            ...mapActions('neNotificacion', ['closeDialogNETramite', 'initialize', 'editItem', 'setDialog']),
            fechaNotificacion(timeStamp) {
                let dt = new Date(timeStamp.toLocaleString());

                let formatted_date = dt.getDate() + "/" + (dt.getMonth() + 1) + "/" + dt.getFullYear() + "-" + dt.getHours() + ":" + dt.getMinutes();
                return formatted_date;
            }
        },
        components: {
            //AsuntoMensajeAdjuntoDialogo
        }
    };
</script>
